import React from "react"
import { css } from "@emotion/react"
import Seo from "~components/Seo"
import Section from "~components/Section"
import ResetForm from "~components/blocks/ResetForm"
import { breakpoints } from '~styles/global'

const Reset = ({ id, code }) => {
  const { mobile, tablet } = breakpoints
  return (
    <>
    <Seo title="Reset"/>
    <Section>
			<ResetForm
				resetUrl={`https://misura-au.myshopify.com/account/reset/${id}/${code}`}
				css={css`
					grid-column: 3 / 6;
					margin-top: 93px;
					${tablet}{
						grid-column: 2 / 6;
					}
					${mobile}{
						grid-column: span 12;
					}
				`} />
		</Section>
    </>
  )
}

export default Reset