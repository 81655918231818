import React, { useState } from 'react'
import { Link, navigate } from "gatsby"
import axios from 'axios'
import { css } from '@emotion/react'
import { breakpoints } from '~styles/global'
import Button from '~components/blocks/Button'
import { useAccount } from '~context/siteContext'
import { useQueryParams, StringParam } from 'use-query-params'
import RichText from '~components/RichText'

const ResetForm = ({ className, text, resetUrl }) => {
  const { tablet } = breakpoints
  const [values, setValues] = useState({email: "", password: ""})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState(false)
  const [urlParams, setUrlParams] = useQueryParams({
    redirect: StringParam
  })

  const getToken = e => {
    e.preventDefault()
		if(values.password !== values.repeat){
			setError("Passwords don't match.")
			return
		}
    setLoading(true)
    axios({
      url: '/api/reset',
      method: 'post',
      data: {
				resetUrl,
        password: values.password,
      }
    }).then(result => {
      setLoading(false)
      if(result.data.error){
        setError(true)
      } else if (result.data?.message?.data?.customerResetByUrl?.customerUserErrors?.length > 0) {
				setError(result.data?.message?.data?.customerResetByUrl?.customerUserErrors[0].message)
			} else {
        navigate(`/account`)
      }
      return null
    })
    .catch(error => {
      setLoading(false)
      console.log(error)
    })
    return false
  }

  return (
    <form
      className={className}
      onSubmit={e => getToken(e)}
      css={css`
        background: var(--beige);
        cursor: ${loading ? 'wait' : ''};
        * {
          cursor: ${loading ? 'wait' : ''};
        }

        h2{
          margin-bottom: 31px;
          ${tablet}{
            margin-bottom: 36px;
          }
        }

        .h6{
          margin-bottom: 45px;
          ${tablet}{
            margin-bottom: 20px;
          }
        }

        input{
          margin-bottom: 31px;
          ${tablet}{
            margin-bottom: 18px;
          }
        }

        > .h5{
          margin-bottom: 42px;
          display: inline-block;
          ${tablet}{
            margin-top: 12px;
            margin-bottom: 30px;
          }
        }
      `}
    >
        <h2>Enter your new password</h2>
        <div className="h6"><RichText content={text}/></div>
				{!success &&
					<>
						<input
							type="password"
							placeholder="Password"
							onFocus={() => setError(false)}
							onChange={e => setValues({...values, password: e.target.value})}
						/>
						<input
							type="password"
							placeholder="Repeat Password"
							onFocus={() => setError(false)}
							onChange={e => setValues({...values, repeat: e.target.value})}
						/>
					</>
				}
        {success &&
					<p>Password reset, redirecting you...</p>
				}
        {error &&
          <div css={css`
            grid-column: span 2;
            margin-top: 20px;
            margin-bottom: 20px;
            color: var(--orange);
          `}>
            <p className="h5">{error}</p>
          </div>
        }
        <Button onClick={() => null} type="submit">
          {loading ? 'Loading' : 'Reset'}
        </Button>
    </form>
  )
}

ResetForm.propTypes = {
}

ResetForm.defaultProps = {
}

export default ResetForm
